.iconsWrapper {
    position: relative;
}

.header {
    /* position: relative !important; */
    position: fixed !important;
    width: 100%;
    background: #f3f3f3;
    z-index: 7500 !important;
}

.header * {
    box-sizing: border-box;
}

.header a {
    position: relative !important;
    z-index: 7500 !important;

    color: #000;
}

.header.fixed {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 5000000;
    width: 100%;
    height: 75px;
    background: #fff;
}

.headerCol a:not(:last-child) {
    margin-bottom: 15px;
}

.headerCol {
    max-width: 15%;
    width: 100%;
    padding: 0 15px;
}

.placeholder {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    opacity: 0;

    transition: opacity .5s ease;
}

.input {
    height: 60px;
    margin-bottom: 0px;
    padding: 20px 50px;
    border: 1px solid #e0e0e0;
    border-radius: 50px;

    width: 100%;

    z-index: 5000;

}

.search {
    position: absolute;
    top: 250%;
    right: 0;
    z-index: 500000;

    min-width: 400px;
    transform: translateY(-50%) scale(0);

    transition: all .5s ease;
}

.searchImage {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
}

.visible {
    transform: translateY(-50%) scale(1);
}

.close {
    width: 1em;
    height: 1em;
    background-color: #ccc;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);

    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    cursor: pointer;
}

.catalog {
    display: flex;

    z-index: 50000;
}

.link:hover .catalogDropdown {
    transform: translateY(0) !important;
}

.link:hover .catalogDropdown {
    display: block;
}

.link:hover {
    cursor: default !important;
}

.link a {
    cursor: pointer;

    transition: opacity .5s ease;
}

.link a:hover {
    opacity: .8;
}

.catalogDropdown > .dropdown {
    transition: transform ease !important;
}

.placeholder.shown {
    opacity: 1;
}

.catalogDropdown .dropdown {
    display: flex !important;

    transition: transform 1s ease;

    position: static !important;
    height: 100%;

}

.catalogDropdown {
    min-width: 100vw;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    height: 450px;
    z-index: 5000 !important;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2) !important;
    transform: translateY(-300%) !important;
    background: #fff !important;

    transition: transform 1s ease;
}

.catalogDropdown > div {
    padding-top: 150px !important;
    background: #fff !important;
    margin: 0 auto;
}

.catalogDropdown > div a:not(.regular) {
    font-weight: 700;
}

.regular {
    font-weight: 400;
    color: #ccc;
}

@media screen and (max-width: 576px) {
    .search {
        min-width: 90vw;
        right: 0;
        z-index: 500000123123123;
    }

    .input {
        width: 100%;
    }
}