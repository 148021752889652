body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  overflow-x: hidden;
}

.splide__pagination {
  display: flex !important;
  justify-content: flex-start !important;

  margin-bottom: 25px !important;
}

.splide__pagination button.splide__pagination__page {
  width: 10px;
  height: 10px;
  background-color: #000;
  opacity: 1;
  margin-right: 30px;
}

.splide__pagination button.splide__pagination__page.is-active {
  background-color: #1D71B8;
  transform: scale(1);
  position: relative;
  top: -4px;
}

.splide__pagination button.splide__pagination__page.is-active:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  content: '';
  background: none;
  border: 1px #D3D3D3 solid;
  border-radius: 50%;
  width: 31px;
  height: 31px;
}

.dropdown-heading {
  font-family: Gotham, sans-serif;
  color: #1d71b8;
  font-size: 12px;
  line-height: 1.44;
  font-weight: 700;
}

.product-dropdown {
  margin: 0 !important;
}

.af-class-splide__slide {
  overflow: hidden;
  min-height: 580px;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
}

.splide__list {
  box-sizing: border-box;
}

.splide__list * {
  box-sizing: border-box;
}

.dropdown-line {
  width: 60px;
  height: 2px;
  background-color: #c2c2c2;
}

.cta-block {
  width: 50%;
  height: 100%;
  background-color: #fff;

  display: flex;
  align-items: center;
}

.cta-block .cta-text-content {
  margin-left: 40px;
}
@media (max-width: 736px) {
  .cta-block .cta-text-content {
    margin-left: 0;
    padding: 15px;
    margin: 0 auto;
  }
}

.slide-r-side {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;

  max-width: 400px;
  margin-left: auto;
}

.product-dropdown-toggle {
  display: flex !important;
  padding: 0px;
  -webkit-align-items: center;
  align-items: center !important;
  grid-column-gap: 27px;
  padding: 0 !important;
}

.product-info-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cta-heading {
  max-width: 400px;
  margin-bottom: 20px;
  font-family: Gotham, sans-serif;
  color: #1d71b8;
  font-size: 52px;
  line-height: 1.07;
  font-weight: 700;
}

.cta-date-text {
  color: #4b4848;
  font-size: 17px;
  font-weight: 400;
}

.cta-product-name {
  font-family: Gotham, sans-serif;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
}

@media (min-width: 992px) {
  .cta-block > .cta-product-img {
    max-width: 30%;
  }
}
.cta-product-img {
  position: absolute;
  /* height: 90%; */
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cta-product-price {
  font-family: Gotham, sans-serif;
  line-height: 1.2;

  margin: 15px 0;
}

.cta-product-price-compare {
  margin-left: 10px;
  font-family: "Gotham book", sans-serif;
  font-weight: 400;
  text-decoration: line-through;
}

.dropdown-info-block.w-dropdown-list {
  padding-left: 87px !important;
  background-color: transparent !important;

  position: static !important;

  width: 100%;
}

.dropdown-info-text {
  margin-bottom: 0px;
  color: #797979;
  font-size: 12px;
}

.af-class-filter--mobile__form-wrapper {
  width: 100% !important;
}

.af-class-map__location-wrapper.shops__map {
  width: 100%;
  height: 100%;
  filter: grayscale(1);
}

.af-class-map__location-wrapper.shops__map > div {
  width: 100%;
  height: 100%;
}

.active {
  color: #1d71b8 !important;
  font-weight: 700 !important;
}

.shipping__tabs {
  display: flex;
  flex-direction: column;
  max-width: 100px;
}

.shipping__container {
  padding-right: 50px;
  padding-left: 50px;

  margin: 100px 0;
}

.shipping__title {
  max-width: 335px !important;
  margin-bottom: 30px !important;
  color: #0e0e0e !important;
  font-size: 50px !important;
}

.shipping__tabs span {
  font-family: Gotham, sans-serif;
  color: #bcbcbc;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  transition: color 0.5s ease;
}

.shipping__tabs span:hover {
  color: #1d71b8;
}

.shipping__tabs span.active {
  color: #1d71b8;
}

.shipping__grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.shipping__innerTab {
  padding: 30px 0;
  cursor: pointer;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  font-size: 24px;

  position: relative;
}

.shipping__innerTab:first-child {
  border-top: 1px solid #ccc;
}

.shipping__innerTab img {
  position: absolute;
  top: 50px;
  right: 25px;
  transform: translateY(-50%) rotate(0);
}

.shipping__tab-text {
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  transition: height 0.5s ease;
}

.shipping__tab-text h4 {
  font-size: 16px;
  font-weight: 500;
}

.shipping__tab-text ul li {
  font-size: 16px;
  font-weight: normal;
  max-width: 600px;
}

.nav-mobile {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 5000;
  overflow-y: scroll;
  padding: 0 0 50px 0 !important;
}

.af-class-nav-drawer--mobile {
  box-sizing: border-box;
  padding-top: 0 !important;

  background: #fff !important;

  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0 25px !important;
}

.af-class-nav-drawer--mobile__dropdown-link .af-class-content_grid-dropdown {
  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding-top: 16px !important;
}

.af-class-nav-drawer--mobile > .af-class-link-nav_drawer-mobile:last-child {
  padding-bottom: 150px !important;
}

.af-class-nav-drawer--mobile__dropdown-link {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  /* justify-content: center !important; */
  transition: none !important;
}

.af-class-nav-drawer--mobile .af-class-link-nav_drawer-mobile.af-class-dropdown {
  color: #797979 !important;
  font-weight: 700;
}

.af-class-nav-drawer--mobile__dropdown-link > .af-class-dropdown__content-wrapper {
  display: none;
}

.af-class-nav-drawer--mobile__dropdown-link.af-dl-active > .af-class-dropdown__content-wrapper {
  display: block;
}

@media screen and (max-width: 991px) {
  .cta-product-img {
    height: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shipping__grid {
    grid-template-columns: 1fr;
  }

  .shipping__tabs {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 991px) {
  .af-view .af-class-map--p-coffee-shops {
    padding-top: 0 !important;
  }

  .af-class-map--p-coffee-shops.af-class-an-load--3 {
    height: 500px !important;
  }

  .shipping__tabs {
    flex-direction: row;
  }

  .shipping__tabs span {
    margin-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  /* .af-view .af-class-icon-wrapper.af-class-search {
    display: block !important;
  } */

  .text-content {
    width: 100% !important;
  }

  .cta-block {
    width: 100%;
    flex-wrap: wrap;
  }

  .cta-product-img {
    position: static;
    transform: none;
    height: 250px;

    margin: 0 auto;
    margin-bottom: 30px;
  }

  .slide-r-side {
    display: none;
  }

  .cta-heading {
    margin: 10px 0;
    font-size: 32px;
  }

  .af-view .af-class-splide__slide.af-class-p-product {
    width: 100% !important;
  }

  .af-view .af-class-splide__slide.af-class-p-product {
    width: 100% !important;
  }

  .shipping__innerTab span {
    font-size: 18px;
  }

  .shipping__tab-text {
    font-size: 14px;
  }

  .shipping__tab-text ul li {
    font-size: 14px;
  }

  .shipping__tab-text h4 {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 576px) {
  .shipping__innerTab span {
    font-size: 16px;
  }

  .shipping__tab-text {
    font-size: 14px;
  }

  .shipping__tab-text ul li {
    font-size: 14px;
  }

  .shipping__tab-text h4 {
    font-size: 14px !important;
  }

  .shipping__innerTab img {
    display: none !important;
  }

  .shipping__tabs span {
    font-size: 14px;
  }

  .shipping__title {
    font-size: 25px !important;
  }

  .shipping__tab-text ul {
    padding-left: 15px !important;
  }

  .shipping__container {
    margin: 50px 0 !important;
  }

  .af-class-buy-button-product-page {
    position: fixed;
    bottom: 15px;
    width: calc(100% - 30px) !important;
    margin: 0;
    padding: 0;
    z-index: 999;
  }
}
