.taste-popup-root {
  align-items: flex-start !important;
  overflow-Y: scroll !important;
}
@media(min-width: 768px) {
  .taste-popup-wrapper {
    max-width: 80% !important;
    padding: 80px 60px !important;
  }
  .taste-popup-tabs {
    font-size: 18px;
    column-gap: 55px;
  }
}
@media(max-width: 767px) {
  .taste-popup-wrapper {
    /* max-width: 100%; */
    /* padding: 0; */
  }
  .taste-popup-tabs {
    font-size: 16px;
    column-gap: 25px;
  }
}
.taste-groups-wrapper {
  display: flex;
  gap: 15px;
}
@media(max-width: 1279px) {
  .taste-groups-wrapper {
    flex-direction: column;
  }
  .taste-groups-wrapper .taste-group-wrapper {
    max-width: 100%;
  }
}
@media(min-width: 1280px) {
  .taste-groups-wrapper {
    flex-direction: row;
  }
  .taste-groups-wrapper .taste-group-wrapper {
    /* max-width: 20%; */
    flex: 1;
  }
}
.taste-groups-wrapper .taste-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
}
