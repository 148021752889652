.splide__arrow.splide__arrow--next {
    display: flex !important;
    background-image: url(../images/slider__arrow--active.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    height: 0.9rem !important;
    right: 0;
    top: 0;
}

.splide__arrow.splide__arrow--prev {
    display: flex !important;
    background-image: url(../images/slider__arrow--active.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    height: 0.9rem !important;
    transform: rotate(-180deg) translateY(50%);
    left: 0;
    top: 0;
}

.af-class-product__img_wrap .af-class-splide__slide {
    min-height: 0 !important;
}

.af-class-product__img_wrap .splide__arrow.splide__arrow--next {
    display: flex !important;
    width: 33px !important;
    height: 33px !important;
    border-radius: 33px;
    background-color: #F5F5F5;
    background-image: url(../images/caret_down.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    transform: rotate(-90deg) translateY(-10px);
    right: 0;
    top: 0;
}

.af-class-product__img_wrap .splide__arrow.splide__arrow--prev {
    display: flex !important;
    width: 33px !important;
    height: 33px !important;
    border-radius: 33px;
    background-color: #F5F5F5;
    background-image: url(../images/caret_down.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    transform: rotate(90deg) translateY(-10px);
    left: 0;
    top: 0;
}

.splide__arrow.splide__arrow--next svg, .splide__arrow.splide__arrow--prev svg {
    display: none;
}