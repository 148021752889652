td {
    padding: 5px;
}

.price-list {
    padding-right: 15px;
    min-width: 1000px;
    font-family: Gotham, sans-serif;
}

.price-list table {
    border-collapse: collapse;
    width: 100%;
}
.price-list > h1 {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 40px;
}

.price-block > h2 {
    font-weight: 400;
    text-transform: uppercase;
    color: #1d71b8;
    display: flex;
    justify-content: space-between;
    font-size: 36px;
    letter-spacing: 0.1em;
    align-items: flex-end;
    position: relative;
}

.price-block > h2 > span {
    display: block;
    z-index: 12;
    background: #f3f3f3;
    padding-right: 27px;
}


.price-title-line {
    height: 4px;
    width: 100%;
    background: #1D71B8;
    position: absolute;
    bottom: 10px;
    left: 0;
}

.price-block {
    margin-top: 100px;
}


.price-list tbody tr td:first-child {
    font-weight: 700;
    font-size: 20px;
    width: 20%;
    position: relative;
}

.price-product-title-new {
    position: absolute;
    top: -10px;
    right: 10px;
    background: #1D71B8;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    padding: 0 15px;
}

.price-product-title-decaf {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #e20e18;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    padding: 0 15px;
}

@media (max-width: 1601px) {
    .price-product-title-new {
        right: 5px;
    }
}

.price-product-title-soon {
    width: 250px;
}


.price-list .price-block-must tbody tr td:first-child {
    width: 35%;
}

.price-list tbody tr td.price-list-td-blue:first-child {
    font-weight: 500;
}


.price-block-5cols tbody tr td:nth-child(2) {
    width: 41%;
}

.price-block-4cols tbody tr td:nth-child(2) {
    width: 54%;
}

.price-block tbody tr td:nth-child(2) {
    padding-left: 20px;
}


.price-list tbody td {
    width: 13%;
    border-right: 2px solid #1D71B8;
}

.price-list tbody h6 {
    font-weight: 700;
    font-size: 14px;
}

.price-list tbody p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16.39px;
    margin-bottom: 0;
}


.price-list-td-blue {
    color: #1D71B8;
    font-weight: 500;
    /* font-family: "Futura", "Helvetica Neue", Helvetica, Arial, sans-serif; */
    font-family: Gotham, sans-serif;
    text-align: center;
}

.price-list-table-line {
    border-bottom: 2px solid #1D71B8;
    height: 16px;
}

.price-list-table-line-space {
    height: 16px;
}

.price-block-3cols tbody tr td:nth-child(2) {
    width: 67%;
}

.price-block-3cols.price-block-must tbody tr td:nth-child(2) {
    width: 52%;
}

.price-block-machines tr td:first-child {
    width: 18%;
}

.price-block-machines tr td:nth-child(2) {
    width: 25%;
}

.price-block-machines-image {
    display: flex;
    justify-content: center;
}

.price-block-machines-image-small img {
    width: 60px;
    height: 50px;
}

.price-block-machines-image-huge img {
    width: 50px;
    height: 50px;
}

.price-block tbody tr td.price-list-price {
    padding-left: 0;
    font-size: 20px;
}

.price-list-td-blue {
    font-size: 20px;
}

.price-block-machines thead tr td:nth-child(n+2) {
    border-right: 2px solid #1D71B8;
}

 .price-list tbody tr td.price-list-td-blue:first-child {
     width: auto;
 }

.price-block-machines tr td.price-list-price:nth-child(2) {
    width: auto;
}

.price-list .price-block-machines tbody td {
    width: auto;
}

.price-list .price-block-machines thead tr:first-child td:nth-child(5),
.price-list .price-block-machines thead tr:first-child td:nth-child(6),
.price-list .price-block-machines thead tr:first-child td:nth-child(7) {
    border-right: none;
}

.price-list .price-block-machines thead tr:nth-child(2) td {
    margin-bottom: 20px;
}

.price-block-machines-tbody-line td:nth-child(n+3) {
    border-bottom: 2px solid #1D71B8;
}

.price-block-machines tbody tr td:first-child,
.price-block-tea tbody tr td:first-child,
.price-block-must tbody tr td:first-child {
    color: #1D71B8;
}

.price-block-tea td h6 {
    margin-top: 10px;
}

.price-block-equipment img {
    max-width: 200px;
}


.price-block-equipment tbody tr td {

}

.price-block-equipment tbody tr td:nth-child(2) {
    font-weight: 700;
    font-size: 20px;
    width: 20%;
    position: relative;
}

.price-block-equipment tbody tr td {
    width: auto;
}

.price-block-equipment thead tr td {

}

.price-block-equipment thead tr td:nth-child(2),
.price-block-equipment thead tr td:nth-child(3),
.price-block-equipment thead tr td:nth-child(4) {

}

.price-block-equipment thead tr td:nth-child(3),
.price-block-equipment thead tr td:nth-child(4),
.price-block-equipment thead tr td:nth-child(5) {
    border-right: 2px solid #1D71B8;
}

.price-block-equipment thead tr td:nth-child(3) {
    border-left: 2px solid #1D71B8;
}

.price-block-machines-tbody-line td:nth-child(3) {
    min-width: 60px;
}
